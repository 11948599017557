// loads jQuery and Axios into the window object
import '@/jquery.js';
import '@/init-globals.js';
import '~/bootstrap';
// import bootstrap.bundle for popper
import 'bootstrap/dist/js/bootstrap.bundle.min.js';
import 'bootstrap/js/dist/tooltip';
import '~/jquery-ui-dist/jquery-ui';
import '~/multiple-select';

// import 'intl-tel-input/build/js/intlTelInput-jquery';
// import 'intl-tel-input/build/js/utils';

import 'gasparesganga-jquery-loading-overlay';
import 'jquery-validation';

import Bugsnag from '@bugsnag/js';
import 'typeahead.js';

import toastr from 'toastr';

fetch('https://cdnjs.cloudflare.com/ajax/libs/tether/1.4.0/js/tether.min.js');
fetch('https://cdnjs.cloudflare.com/ajax/libs/jquery.appear/0.3.3/jquery.appear.min.js');

// const $ = window.jQuery;

window.toastr = toastr;

// The problem with using process.env.NODE_ENV for our 'releaseStage' is that NODE_ENV is specific to the
// npm environment used for building. In our case, we build the JS and CSS locally and deploy the same for all
// environments (local, demo, production etc.). We do this so we only have to build JS/CSS in one place (locally) which
// keeps us from having to install npm on demo/test/production server. However, this means our npm environment is
// the same regardless of the actual environment where code is deployed so we cannot use NODE_ENV here.
if (window.location.hostname.includes('closinglock.com')) {
    Bugsnag.start({
        apiKey: 'dc941bd981878753c264335022d3e612',
        releaseStage: 'productionJS',
    });
    window.Bugsnag = Bugsnag;
}

// Functions for showing/hiding spinner icons on buttons for visual feedback to user that something is processing
var spinnerButtonOriginalHTML = {};

window.showSpinner = function showSpinner(button) {
    const $button = $(button);
    $button.prop('disabled', true);
    spinnerButtonOriginalHTML[button] = $button.html();

    // .width() rounds to nearest integer. getBoundingClientRect() allows for fractional width
    var height = $button[0].getBoundingClientRect().height;
    var width = $button[0].getBoundingClientRect().width;

    $button.html('<i class="fas fa-spinner fa-pulse" style="letter-spacing: normal"></i>');

    // Set the height/width using the same method as retrieving the height/width
    $button.css('height', height + 'px');
    $button.css('width', width + 'px');
};

window.hideSpinner = function hideSpinner(button) {
    const $button = $(button);
    $button.prop('disabled', false);
    $button.html(spinnerButtonOriginalHTML[button]);
};

console.log('app.js loaded');
