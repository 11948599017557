import axios from 'axios';
import _ from 'lodash';

if (window.jQuery === undefined) {
    console.log('window.jQuery is undefined in init-globals.js');
    window.jQuery = require('jquery');
}
// import './jquery';

window._ = _;
// window.$ = window.jQuery = jQuery;
window.axios = axios;

window.axios.defaults.headers.common['X-Requested-With'] = 'XMLHttpRequest';

// const $ = window.jQuery;

$.ajaxSetup({
    headers: {
        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content'),
    },
});

$.fn.intlTelInput = function ($) {
    console.log('intlTelInput', $, this);
};

console.log('init-globals.js loaded');
